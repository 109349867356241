import React from "react";
/* global google */


class GoogleSearch extends React.Component {
    constructor(props) {
        super(props);
        this.autocompleteInput = React.createRef();
        this.autocomplete = null;
        this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
    }

    componentDidMount() {
        this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.current,
            { "types": ["geocode"] });

        this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
    }

    handlePlaceChanged() {
        const place = this.autocomplete.getPlace();
        this.props.onPlaceLoaded(place);
    }

    render() {
        return (
            <div className="MuiFormControl-root MuiTextField-root">
                <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl">
                    <input ref={this.autocompleteInput} placeholder={`Search Your City`} id="autocomplete" className="MuiInputBase-input MuiInput-input"
                        type="text"></input>
                </div>
            </div>
        );
    }
}

export default GoogleSearch;