/** @format */

import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { useLogin, useNotify, Notification } from "react-admin";
import { ThemeProvider } from "@material-ui/styles";
import { theme } from "./theme";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const login = useLogin();
  const notify = useNotify();
  const submit = (e) => {
    setLoading(true);
    e.preventDefault();
    login({ username, password }).catch(() =>
      notify("Invalid username or password")
    );
    setLoading(false);
  };
  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        flexDirection="column"
        spacing={16}
        style={{
          width: "100%",
          height: "100vh",
          textAlign: "center",
          backgroundColor: "rgb(246, 246, 248)",
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            alignItems: "flex-end",
            textAlign: "center",
            justifyContent: "center",
            marginBottom: 20,
            marginTop: -140,
          }}
        >
          <img
            src="web_logo.png"
            id="Logo-Vertical-FC"
            sizes="(max-width: 300px) 173.625px, 217.03125px"
            alt=""
            className="image-6"
            style={{maxWidth: 300}}
          />
        </Grid>
        <Grid item xs={12}>
          <form
            onSubmit={submit}
            style={{
              backgroundColor: "white",
              borderRadius: 15,
              width: 400,
              boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.08)",
              padding: 40,
              margin: "auto",
            }}
          >
            <div style={{ textAlign: "left", marginBottom: 8 }}>
              <b style={{ fontWeight: 700 }}>UserName</b>
            </div>
            <input
              id="username"
              label="Username"
              variant="outlined"
              style={{
                marginBottom: 20,
                height: 52,
                background: "rgb(246, 246, 248)",
                borderRadius: 10,
                border: "none",
                outline: "none",
                padding: "0 20px",
                width: "100%",
                boxSizing: "border-box",
                fontSize: 16,
                fontWeight: 500,
              }}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <div style={{ textAlign: "left", marginBottom: 8 }}>
              <b style={{ fontWeight: 700 }}>Password</b>
            </div>
            <input
              type="password"
              id="password"
              label="Password"
              variant="outlined"
              style={{
                marginBottom: 20,
                height: 52,
                background: "rgb(246, 246, 248)",
                borderRadius: 10,
                border: "none",
                outline: "none",
                padding: "0 20px",
                width: "100%",
                boxSizing: "border-box",
                fontSize: 16,
                fontWeight: 500,
              }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              disabled={loading}
              type="submit"
              style={{
                backgroundImage:
                  "radial-gradient(circle farthest-corner at 100% 100%,#fd1d3e,#ff9818,#ffe05e)",
                color: "white",
                fontWeight: 700,
                borderRadius: 10,
                width: 160,
                height: 52,
                opacity: loading ? 0.6 : 1,
                fontFamily: "Quicksand",
              }}
            >
              {loading ? <CircularProgress color="white" size={20} /> : "Login"}
            </Button>
          </form>
        </Grid>
      </Grid>
      <Notification />
    </ThemeProvider>
  );
};

export default LoginPage;
